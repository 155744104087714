@import 'assets/include-media';

.App {
  text-align: center;
  color: white;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;

  header.top {
    background-color: #2F2F2F;
    position: sticky;
    top: 0;
    z-index: 100;
    font-family: 'Montserrat Alternates', sans-serif;
    
    .content {
      display: flex;
      width: 1200px;
      padding: 2px 20px;
      margin: 0 auto;
      
      @include media("<=1200px") {
        width: unset;
      }
    }

    h1 {
      font-size: 24px;

      & strong {
        color: orange;
      }
    }
  }

  section.main {
    margin-top: 20px;

    .forecast-container {
      font-family: 'Montserrat Alternates', sans-serif;
      width: 800px;
      max-width: 95%;
      margin: 0 auto;
    }

    .title {
      display: flex;
      justify-content: space-between;
      margin: 48px auto;
    }

    .range {
      background-color: #052BB5;
      border-radius: 4px;
      padding: 4px 8px;
    }

    .q-a {
      text-align: left;
      max-width: 95%;
      width: 720px;
      margin: 72px auto;

      h2 {
        font-size: 26px;  
      }

      h3 {
        font-size: 24px;
        margin-top: 36px;
        margin-bottom: 24px;
        border-top: 1px dashed #636363;
        padding-top: 20px;
        line-height: 36px;
      }

      p {
        font-size: 18px;
        margin-top: 8px;
        line-height: 32px;
        color: rgb(223, 223, 223);
      }

      .note {
        padding: 8px;
        font-size: 16px;
        border-radius: 8px;
      }

      .highlighted {
        background-color: rgba(white, 0.15);
        transition: 0.5s all 0.3s;
        transform: translateY(-10px);
      }  
    }

    a {
      color: #5289ff;

      &:hover {
        color: #84abfd;
      }
    }

    .center {
      text-align: center;
    }

    p.orange {
      color: orange;
    }

    .donate-button {
      display: inline-block;
      border: 2px solid orange;
      color: orange;
      padding: 4px 16px;
      text-decoration: none;
      border-radius: 8px;
      font-size: 18px;
      margin: 8px 0;

      &:hover {
        color: orange;
        filter: brightness(1.3);
        transition: 0.3s;
      }
    }

    .view-qr-code {
      display: inline;
      font-size: 16px;
      line-height: 16px;
      font-weight: bold;
      color: #5289ff;
      cursor: pointer;

      &:hover {
        color: #84abfd;
        text-decoration: underline;
      }
    }

    .qr-code-container {
      padding: 10px;
      background-color: white;
      border-radius: 4px;
      display: inline-block;

      > svg {
        display: block;
      }
    }
  }

  .not-your-keys-not-your-coins {
    font-size: 0.85rem;
    line-height: 1.3rem;
    text-align: center;
    max-width: 90%;
    width: 800px;
    margin: 20px auto;

    a {
      color: white;
    }
  }
}
