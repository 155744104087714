@import '../assets/include-media';

.__calculator {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;

  .input-container {
    display: flex;
    border: 1px solid #a3a3a3;
    border-radius: 6px;
    padding: 4px;
    gap: 12px;

    &:hover {
      border-color: #CFCFCF;
    }

    input {
      border: none;
      font-family: inherit;
      background-color: transparent;
      color: white;
      font-size: 22px;
      font-weight: bold;
      width: 88px;
      padding: 0 8px;

      &:focus {
        outline: none;
      }

      @include media("<=414px") {
        font-size: 18px;
        max-width: 52px;
      }
    }

    .currency {
      background-color: #323232;
      border-radius: 4px;
      padding: 4px 8px;
      text-align: center;
      border: none;
      font-size: 22px;
      font-family: 'Montserrat Alternates', sans-serif;
      color: white;
      font-weight: bold;

      @include media("<=414px") {
        font-size: 18px;
      }    
    }
  }
}